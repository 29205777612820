<template>
  <div class="users-page admin-page">
    <div class="container">
      <AdminHeader />
      <div class="heading">
        <v-breadcrumbs
          class="breadcrumbs"
          :items="[
            {
              text: 'Benutzer',
              to: '/admin/users',
            },
          ]"
        >
          <template v-slot:divider>
            <v-icon>chevron_right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" exact exact-active-class="active">
              <h2 class="breadcrumbItem" v-if="item.to == $route.path">
                {{ item.text }}
              </h2>
              <p class="breadcrumbItem" v-else>{{ item.text }}</p>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-btn class="add" color="primary" elevation="0" to="/admin/users/new">
        <v-icon left> add </v-icon>
        Benutzer hinzufügen
      </v-btn>

      <v-list two-line class="list">
        <v-list-item-group v-if="users && users.users">
          <template v-for="(item, index) in users.users">
            <v-list-item
              class="item"
              :key="item.id"
              :to="'/admin/users/' + item.id"
            >
              <template v-slot:default>
                <v-list-item-content>
                  <v-list-item-title
                    class="itemTitle"
                    v-text="item.name"
                  ></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="
                      item.admin
                        ? 'Administrator'
                        : item.canDebit
                        ? 'Gutschein-Abbuchung bei ' +
                          item.canDebit +
                          ' Kampagne' +
                          (item.canDebit != 1 ? 'n' : '')
                        : 'Keine Berechtigungen'
                    "
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>chevron_right</v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              class="mx-3"
              v-if="index < users.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>

    <v-dialog v-model="error.active" persistent max-width="500">
      <v-card>
        <template v-if="error.code == 'notFound'">
          <v-card-title style="word-break: break-word;">
            Benutzer nicht gefunden
          </v-card-title>
          <v-card-text>
            Der Benutzer mit der angegebenen ID konnte nicht gefunden werden. Du
            kannst diesen aber erstellen.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error.code == 'unknownDoc'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Aufruf des Benutzers
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Benutzer mit der angegebenen ID
            aufzurufen, ist ein unerwarteter Feher aufgetreten. Bitte versuche
            es später erneut oder wende dich an den Support.<br />
            Fehlercode: {{ $route.query.code }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dismissErrorDialog">OK</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.active"
      :timeout="snackbar.code === 'created' ? 6000 : 2000"
      @input="
        $event == false
          ? $router.replace({
              path: '/admin/users',
            })
          : null
      "
    >
      <template v-if="snackbar.code === 'created'">
        Benutzer wird erstellt...
      </template>
      <template v-if="snackbar.code === 'userDeleted'">
        Benutzer wird gelöscht...
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { db } from '@/firebase';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminCampaigns',
  components: { AdminHeader },
  firestore: {
    users: db.doc('aggregation/users'),
  },
  data() {
    return {
      users: null,
      error: {
        active: false,
        code: '',
      },
      snackbar: {
        active: false,
        code: '',
      },
    };
  },
  watch: {
    '$route.query.error': {
      handler(code) {
        if (code) this.error = { active: true, code };
      },
      immediate: true,
    },
    '$route.query.snackbar': {
      handler(snackbar) {
        if (!snackbar) return;
        this.snackbar = { active: true, code: snackbar };
      },
      immediate: true,
    },
  },
  methods: {
    dismissErrorDialog() {
      this.error = { active: false, code: '' };
      this.$router.replace({ path: '/admin/users' });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.users-page {
  .container {
    .add {
      margin-bottom: 16px;
    }

    .list {
      border-radius: 8px;
      border: 1px solid rgba(#000000, 0.1);

      .item {
        .itemTitle {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
